const Footer = () => {

    return (
        <div style={{ backgroundColor: '#252526' }} className="text-center p-3 mt-5">
            <h5>Created with <i style={{ color: 'red' }} className="fas fa-heart"></i> by me !</h5>
        </div>
    )
}

export default Footer;
